<template class="container-fluid bg-light">
    <div class="LayoutDefault px-0 pt-0" style="width:100%">
        <!-- <header> -->
<!--             
            <b-navbar class="pt-0 pb-0 spainhNavbar" toggleable="lg" :sticky="true" style="z-index: 3 !important; position: fixed; width: 100%;" v-bind:class="{'is-pinned':isFixed, 'is-not-pinned':!isFixed}">
                <b-navbar-brand href="/" class="d-flex pb-4">
                    <div class="pt-0 d-flex" style="margin-left:6rem">
                        <img src="../assets/CastillosLOGO.png" class="img-fluid my-auto" style="height:80px;width:200px" />
                    </div>
                </b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav align="center" class="pe-5 ms-auto mt-auto mb-lg-0 mb-md-0 false" :small="true">
                        <b-nav-item class="spainhn-main-menu-text text-monospace mt-auto border-start h-100" href="/">
                            <p class="mt-auto pt-3" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_INICIO')}}</p>
                        </b-nav-item>
                        <b-nav-item class="spainhn-main-menu-text text-monospace mt-auto border-start" href="/castillos">
                            <p style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_MONUMENTOS')}}</p>
                        </b-nav-item>
                        <b-nav-item class="spainhn-main-menu-text text-monospace mt-auto border-start h-100" href="/rutas-recomendadas">
                            <p style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_RUTAS')}}</p>
                        </b-nav-item>
                        <b-nav-item class="spainhn-main-menu-text mt-auto border-start" href="/blog">
                            <p style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_NOTICIAS') }}</p>
                        </b-nav-item>

                        <b-nav-item-dropdown id="servicios-dropdown" right dropright class="spainhn-main-menu-text my-auto border-start"  :text="$t('default.MAIN_MENU_SERVICIOS')">
                            <b-dropdown-item class="spainhn-main-menu-text" href="/castillos/servicios/visitas">{{ $t('default.MAIN_MENU_SERVICES_VISITS') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/alojamiento">{{ $t('default.MAIN_MENU_SERVICES_ALOJAMIENTO') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/restaurantes">{{ $t('default.MAIN_MENU_SERVICES_RESTAURANTES') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/castillos/servicios/eventos-singulares">{{ $t('default.MAIN_MENU_SERVICES_EVENTOS') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/castillos/servicios/experiencias-exclusivas">{{ $t('default.MAIN_MENU_SERVICES_EXPERIENCIAS') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/bodas">{{ $t('default.MAIN_MENU_SERVICES_BODA') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/espacios">{{ $t('default.MAIN_MENU_SERVICES_ESPACIOS') }}</b-dropdown-item>
                            <b-dropdown-item class="spainhn-main-menu-text" href="/servicios/exposiciones">{{ $t('default.MAIN_MENU_SERVICES_MUSEOS') }}</b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item class="spainhn-main-menu-text mt-auto border-start border-end me-2 d-flex w-100" href="/clientes/login">
                            <div class="d-flex">
                                <font-awesome-icon class="mt-1 me-1" icon="fa-circle-user"></font-awesome-icon>
                                <p class="" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_ACCEDER')}}</p>
                            </div>
                        </b-nav-item>
                        <div class="d-flex  my-auto h-100">
                            <div class="d-flex my-auto">
                            <font-awesome-icon class="my-auto" icon="fa-cart-shopping"></font-awesome-icon>
                            </div>
                        </div>
                        <b-nav-item-dropdown right class="spainhn-main-menu-text my-auto " no-caret :text="ticketsAmount" href="/carrito">
                            <b-dropdown-item v-for="(item) in itemsInCart" v-bind:key="item.uuid">
                                <entrada-resumen class="me-auto" :item="item"/>
                            </b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item>
                                <strong>Importe Total {{(totalAPagar).toLocaleString()}} €</strong>
                            </b-dropdown-item>
                            <b-dropdown-item class="mx-auto" href="/carrito">
                                <strong class="mx-auto">Ver carrito</strong>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <div role="button" @click="changeLanguage" class="d-flex">
                            <country-flag class="my-auto" style="border-radius:40% 40% 50% 50%;" :country="idioma"  size='normal'/>
                        </div>
                    </b-navbar-nav>
                    
                </b-collapse>
                
            </b-navbar>
             -->

        <nav class="navbar navbar-light fixed-top bg-white spainhNavbar py-3 navbar-expand-lg w-100 pe-4" v-bind:class="{'is-pinned':isFixed, 'is-not-pinned':!isFixed}">
                <div class="container-fluid ps-0 w-100">
                    <a class="navbar-brand ms-5" href="/" >
                        <img src="../assets/LOGO REPAHIS.png" style="height:80px;width:auto;" class="align-text-top d-inline-block"/>
                    </a>

                    <button class="navbar-toggler border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse ms-auto" id="navbarNavAltMarkup">
                        <ul class="ms-auto navbar-nav spainhn-main-menu-text text-monospace border-start">
                           <li class="nav-item">
                                <a class="nav-link" style="color:rgb(77,77,77) !important;" href="/">{{ $t('default.MAIN_MENU_INICIO')}}</a>
                           </li> 
                            <li class="nav-item border-start">
                                <a class="nav-link" style="color:rgb(77,77,77) !important;" href="/castillos">{{ $t('default.MAIN_MENU_MONUMENTOS')}}</a>
                            </li>
                            <li class="nav-item h-100 border-start">
                                <a class="nav-link" href="/rutas-recomendadas" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_RUTAS')}}</a>
                            </li>
                            <li class="nav-item border-start">
                                <a class="nav-link"  href="/blog" style="color:rgb(77,77,77) !important;">{{ $t('default.MAIN_MENU_NOTICIAS') }}</a>
                            </li>

                            <li class="nav-item dropdown my-auto border-start">
                                <a class="nav-link dropdown-toggle my-auto" style="color:rgb(77,77,77) !important;" href="#" id="serviceDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{$t('default.MAIN_MENU_SERVICIOS')}}
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="serviceDropdownLink">
                                    <li><a class="dropdown-item" href="/servicios/visitas">{{ $t('default.MAIN_MENU_SERVICES_VISITS') }}</a></li>
                                    <li><a class="dropdown-item" href="/servicios/alojamiento">{{ $t('default.MAIN_MENU_SERVICES_ALOJAMIENTO') }}</a></li>
                                    <li><a class="dropdown-item" href="/servicios/restaurantes">{{ $t('default.MAIN_MENU_SERVICES_RESTAURANTES') }}</a></li>
                                    <li><a class="dropdown-item" href="/castillos/servicios/eventos-singulares">{{ $t('default.MAIN_MENU_SERVICES_EVENTOS') }}</a></li>
                                    <li><a class="dropdown-item" href="/castillos/servicios/experiencias-exclusivas">{{ $t('default.MAIN_MENU_SERVICES_EXPERIENCIAS') }}</a></li>
                                    <li><a class="dropdown-item" href="/servicios/bodas">{{ $t('default.MAIN_MENU_SERVICES_BODA') }}</a></li>
                                    <li><a class="dropdown-item" href="/servicios/espacios">{{ $t('default.MAIN_MENU_SERVICES_ESPACIOS') }}</a></li>
                                    <li><a class="dropdown-item" href="/servicios/exposiciones">{{ $t('default.MAIN_MENU_SERVICES_MUSEOS') }}</a></li>
                                </ul>
                            </li>

                            <li class="nav-item border-start">
                                <a class="nav-link" href="/clientes/login" style="color:rgb(77,77,77) !important;">
                                    <font-awesome-icon class="mt-1 me-1" icon="fa-circle-user"></font-awesome-icon>
                                    {{ $t('default.MAIN_MENU_ACCEDER')}}
                                </a>
                            </li>

                            <li class="nav-item dropdown my-auto border-start d-lg-inline-block d-none">
                                <a class="nav-link dropdown-toggle my-auto" style="color:rgb(77,77,77) !important;" href="#" id="carritoDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <font-awesome-icon class="my-auto" icon="fa-cart-shopping"></font-awesome-icon>
                                    {{ticketsAmount}}
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="serviceDropdownLink">
                                    <li class="dropdown-item" v-for="(item) in itemsInCart" v-bind:key="item.uuid">
                                        <entrada-resumen class="me-auto" :item="item"/>
                                    </li>
                                    <li><hr class="dropdown-divider"></li>
                                    <li>
                                        <a class=" dropdown-item nav-link"><strong>Importe Total {{(totalAPagar).toLocaleString()}} €</strong></a>
                                    </li>
                                    <li class="mx-auto">
                                        <a class="dropdown-item nav-link" href="/carrito"><strong class="mx-auto">Ver carrito</strong></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <span>
                            <div class="dropdown flag-menu-main">
                                <a class="nav-link dropdown-toggle my-auto dropdown-flag" style="color:rgb(77,77,77) !important;" href="#" id="languageDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <a v-if="$i18n.locale === 'es'" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='es'; $store.commit('setIdioma', this.$i18n.locale); }"><img src="../assets/Spain_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>ES</a>
                                    <a v-if="$i18n.locale === 'en'" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='en';$store.commit('setIdioma', this.$i18n.locale); }"><img src="../assets/uk_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>EN</a>
                                    <a v-if="$i18n.locale === 'fr'" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='fr'; $store.commit('setIdioma', this.$i18n.locale); }"><img src="../assets/france_flags.png" style="width: 30px;margin-right:20px;" alt="flag"/>FR</a>
                                    <a v-if="$i18n.locale === 'de'" class="dropdown-item d-inline" role="button" @click="() => { $i18n.locale='de';$store.commit('setIdioma', this.$i18n.locale); }"><img src="../assets/germany_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>DE</a>
                                </a>
                                <ul role="menu" tabindex="-1" class="dropdown-menu dropdown-menu-end flag-dropdown-menu" aria-labelledby="languageDropdownLink">
                                    <li><a class="dropdown-item" role="button" @click="() => { $i18n.locale='es'; $store.commit('setIdioma', this.$i18n.locale); }"><img src="../assets/Spain_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>ES</a></li>
                                    <li><a class="dropdown-item" role="button" @click="() => { $i18n.locale='en';$store.commit('setIdioma', this.$i18n.locale); }"><img src="../assets/uk_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>EN</a></li>
                                    <li><a class="dropdown-item" role="button" @click="() => { $i18n.locale='fr'; $store.commit('setIdioma', this.$i18n.locale); }"><img src="../assets/france_flags.png" style="width: 30px;margin-right:20px;" alt="flag"/>FR</a></li>
                                    <li><a class="dropdown-item" role="button" @click="() => { $i18n.locale='de';$store.commit('setIdioma', this.$i18n.locale); }"><img src="../assets/germany_flag.png" style="width: 30px;margin-right:20px;" alt="flag"/>DE</a></li>
                                </ul>
                            </div>
                        </span>
                    </div>
                </div>
        </nav>            
        <!-- </header> -->
        <main class="LayoutDefault__main bg-light container-fluid px-0">
            <slot/>

            <div class="fixed-bottom d-flex justify-content-start d-lg-none">
                <div class="p-2 bg-white rounded opacity-75 ms-3 mb-3 shadow-lg" role="button" @click="() => { $router.push('/carrito') }">
                    <font-awesome-icon class="text-align-start" size="1x" icon="fa-cart-shopping me-auto"></font-awesome-icon>
                    {{ticketsAmountSmall}}
                </div>  
            </div>
        </main>
        <div class="d-flex w-100 newsletter" style="min-height:30rem;" v-bind:style="{backgroundImage:newsletterImage}">
            <div class="d-flex mx-auto my-auto" style="min-height:18em; width:28vw; min-width:300px;">
                <div class="mx-auto bg-white px-3 pt-3 pb-3" style="border-radius:10px;">
                    <b-form class="pb-3">
                        <h5 class="mx-auto spainhn-subtitle-text"><small>Suscríbete a nuestro newsletter</small></h5>
                        <p class="spainhn-small-text"><strong>Recibe novedades y descuentos de Spain Heritage Network y comienza tu aventura</strong></p>
                        <b-form-group class="my-0">
                            <p class="spainhn-text my-0"><strong>INTRODUCE TU EMAIL</strong></p>
                            <b-form-input type="email" size="sm" style="border-radius:10px;" class="w-50 mx-auto" placeholder="hola@ejemplo.com"></b-form-input>
                        </b-form-group>
                        <b-form-checkbox class="text-start d-flex my-3">
                            <small>Acepto los <a class="link-dark" href="/condiciones-generales">términos y condiciones de privacidad</a> de Spain  Heritage Network</small>
                        </b-form-checkbox>
                        <mdb-btn color="indigo" class="spainhn-button">¡Suscribirme!</mdb-btn>
                        <a class="spainhn-small-text mt-3 link-dark d-block" href="/privacidad-cookies"><small><strong>Acerca del tratamiento de datos de Spain Heritage Network</strong></small></a>
                    </b-form>
                </div>
            </div>
        </div>
        <footer class="LayoutDefault_footer bg-dark text-white">
            <!-- <cookie-law theme="dark-lime">
                <div slot="message">
                </div>
                <div slot-scope="props">
                    <div class="d-flex w-100 copyright-footer">
                        <p class="ms-auto me-4 my-auto" style="text-align:left">
                            Este sitio web utiliza cookies propias para mejorar su experiencia de navegación. <router-link to="privacidad-cookies">Políticas de privacidad</router-link>
                        </p>
                        <div class="d-flex ms-auto">
                            <button class="skew ms-auto" @click="props.accept"><span>Acepto</span></button>            
                            <button class="skew" @click="props.close"><span>Ignorar</span></button>
                        </div>

                    </div>
                </div>
            </cookie-law> -->
            
            <div class="row w-100 pt-4 footer-wrapper m-0" style="padding-bottom:6.5rem;justify-content: center">
                <b-col cols="12" sm="6" lg="3" class="ps-4 mb-4 mb-md-0 footer-logo">
                    <img src="@/assets/LOGO REPAHIS (BLANCO SIN FONDO).png" class="img-fluid w-50"/>
                </b-col>
                <b-col sm="6" lg="3">
                    
                        <div class="d-flex align-items-start">
                            <small class="fw-bold" style="font-size:small;text-align:left">Spain Heritage Network</small>
                        </div>
                        <div class="d-flex flex-column mt-3 text-start spainhn-text">
                            <a href="/quienes-somos" class="link-light"><small>{{$t('default.FOOTER_WHO')}}</small></a>
                            <a href="/aviso-legal" class="link-light"><small>{{$t('default.FOOTER_LEGAL')}}</small></a>
                            <a href="/privacidad-cookies" class="link-light"><small>{{$t('default.FOOTER_PRIVACY_AND_COOKIES')}}</small></a>
                            <a href="/condiciones-generales" class="link-light"><small>{{$t('default.FOOTER_SELL_GENERALS')}}</small></a>
                            <a href="/contacto" class="link-light"><small>{{$t('default.FOOTER_CONTACT')}}</small></a>
                            <a href="https://admin.spainheritagenetwork.com" class="link-light"><small>  {{$t('default.FOOTER_ACCESS')}}  </small></a>
                        </div>
                    
                </b-col>
                <b-col sm="6" lg="3" class="ps-0 ps-md-4">
                    <div class="d-flex align-items-start text-start">
                        <small class="fw-bold" style="font-size:small">{{ $t("default.FOOTER.CONTACT") }}</small>
                    </div>
                    <div class="d-flex flex-column align-items-start text-start mt-3 text-start spainhn-text">
                        <small>Email: info@spainheritagenetwork.com</small>
                        <div class="d-flex mt-3 text-start">
                            <a href="https://www.facebook.com/castillosypalaciosdeespana" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-facebook-square" size="2x"></font-awesome-icon></a>
                            <a href="https://twitter.com/redcastillos" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-twitter-square" size="2x"></font-awesome-icon></a>
                            <a href="https://www.instagram.com/redcastillosypalacios/" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-instagram-square" size="2x"></font-awesome-icon></a>
                            <a href="https://www.linkedin.com/company/asociaci%C3%B3n-y-club-de-producto-castillos-y-palacios-tur%C3%ADsticos-de-espa%C3%B1a/" class="link-light"><font-awesome-icon class="me-2" icon="fa-brands fa-linkedin" size="2x"></font-awesome-icon></a>
                        </div>
                    </div>
                </b-col>
                <b-col v-if="$i18n.locale === 'fr' || $i18n.locale === 'de'" cols="12" sm="6" lg="2" class="ps-4 mb-4 mb-md-0 footer-rightlogo">
                    <img src="@/assets/PIE_MINISTERIO.jpg" class="img-fluid" style="width:80%"/>
                </b-col>
            </div>
        </footer>
    </div>
</template>

<script>

/* eslint-disable vue/no-unused-components */
const defaultLayout = 'AppLayoutDefault';
import EntradaResumen from '../components/EntradaResumen.vue'
import CountryFlag from 'vue-country-flag'
// import CookieLaw from 'vue-cookie-law';
// import IconBase from '../components/IconBase.vue';
// import IconBaseStrech from '../components/IconBaseStrech.vue'
// import IconBase from '../components/IconBase.vue';

const el = document.querySelector(".spainhNavbar")
const observer = new IntersectionObserver( 
  ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
  { threshold: [1] }
);

// observer.observe(el);

export default {
    name:"SpainHeritageAdminLayout",
    components:{

        CountryFlag,
        EntradaResumen,
        // CookieLaw
        // IconBase,
        // IconBaseStrech
        // IconBase
    },
    data(){
        return{
            newsletterImage: `url(${require('@/assets/newsletter-background.jpg')})`,
            idioma: 'es',
            scrolling: false
        }
    },
    methods:{
        changeLanguage(){
            let $vm = this;
            if(this.$i18n.locale == 'es'){
                this.$i18n.locale="en";
                $vm.idioma = 'gb';
            }
            else if(this.$i18n.locale == 'en'){
                console.log("Cambiando a francés idioma");
                this.$i18n.locale = "fr";
                $vm.idioma = 'fr';
            }
            else if(this.$i18n.locale == 'fr'){
                this.$i18n.locale = "de";
                $vm.idioma = 'de';
            }
            else if(this.$i18n.locale == 'de'){
                this.$i18n.locale = "es";
                $vm.idioma = 'es';
            }
            
            $vm.$store.commit('setIdioma', this.$i18n.locale);
        },
        handleScroll(){
            if(window.pageYOffset > 10.0)
                this.scrolling = true;
            else
                this.scrolling = false;
        }
    },
    computed: {
        layout(){
            const layout = this.$route.meta.layout || defaultLayout;
            return () => import(`@/layouts/${layout}.vue`)
        },
        ticketsAmount(){

            let amount = this.$store.getters.numberOfTickets;

            if(amount == 0){
                return this.$t('default.MAIN_MENU_CARRITO');
            }
            else{
                return amount + " Visita(s)";
            }
        },
        ticketsAmountSmall(){
            let amount = this.$store.getters.numberOfTickets;

            if(amount == 0){
                return "(0)";
            }
            else{
                return `(${amount})`;
            }

        },
        itemsInCart(){
            return this.$store.getters.itemsInCart;
        },
        totalAPagar(){
            let totalAPagar = this.$store.getters.total;

            if(totalAPagar != null){
                return totalAPagar.toLocaleString('es-ES', { maximumFractionDigits:2, minimumFracionDigits:2 } );
            }
            else{
                return "ups"
            }
            // return this.$store.getters.total;
        },
        isFixed(){
            if(this.scrolling ) {
                return true;
            }
            else{
                return false;
            }
        }
    },
    created(){
        let $vm = this;
        $vm.$i18n.locale = $vm.$store.getters.getIdioma;

        window.addEventListener('scroll', this.handleScroll);

        if($vm.$i18n.locale == null){
            $vm.$i18n.locale = 'es';
        }
        if(this.$i18n.locale == 'en'){
            // this.$i18n.locale="es";
            $vm.idioma = 'gb';
        }
        else if(this.$i18n.locale == 'es'){
            // this.$i18n.locale = "en";
            $vm.idioma = 'es';
        }
        else if(this.$i18n.locale == 'fr'){
            $vm.idioma = 'fr';
        }
        else if(this.$i18n.locale == 'de'){
            $vm.idioma = 'de';
        }
    },
    mounted(){
        // console.log(el);
        // observer.observe(el);
    },
    destroyed(){
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>
    .newsletter{
        /* background-image: `url(${require('@/assets/newsletter-background.jpg')})`; */
        /* background-image: url('../assets/newsletter-background.jpg'); */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .dropdown-flag{
        background: #fff;
        border-radius: 10px;
        border:1px solid gray;
    }
    .dropdown-flag .dropdown-item{
        padding: 0.75rem 0rem;
    }
    .dropdown-flag .dropdown-item:hover{
        background: none;
    }
    /* styles for when the header is in sticky mode */
    .is-pinned {
        background-color: rgba(255,255,255,1) !important;
    } 

    .is-not-pinned{
        background-color: rgba(255,255,255,0.6) !important;
    }
    .dropdown-menu.flag-dropdown-menu{
        border-radius: 12px;
        box-shadow: 0px 1px 10px #817e7e;
        border: 0;
        min-width:8rem;
    }
    .navbar-toggler:focus{
        box-shadow: none;
    }
    @media(max-width:1440px){
        .LayoutDefault_footer .footer-logo img{
            width:80% !important;
        }
        .LayoutDefault_footer .footer-rightlogo img{
            width:100% !important;
        }
        .spainhNavbar .navbar-collapse{
            display: block !important;
        }
        ul.navbar-nav{
            justify-content: end;
        }
        .flag-menu-main{
            text-align: right;
            margin-top: 10px;
        }
        .flag-menu-main .dropdown-flag{
            display: inline-block;
        }
        .spainhNavbar .navbar-brand{
            margin-left: 20px !important;
        }
    }
    @media(max-width:1199px){
        .spainhn-main-menu-text{
            font-size: 14px !important;
        }
    }
    @media(max-width:991px){
         .LayoutDefault_footer .footer-logo img{
            width:initial !important;
            max-width: 200px;
        }
        .LayoutDefault_footer .footer-rightlogo img{
            width:initial !important;
            max-width: 250px;
        }
        .LayoutDefault_footer .col-sm-6{
            text-align: left;
            padding: 0 !important;
        }  
        .LayoutDefault_footer .footer-wrapper{
            padding: 24px 30px 170px 30px !important;
            justify-content: start !important;
        }
        .LayoutDefault_footer .footer-wrapper>div{
            padding-bottom:20px !important;
        }
        .flag-menu-main{
            text-align: center;
        }
        .dropdown-menu.flag-dropdown-menu{
            right: 50%;
            left: 50%;
            transform: translateX(-50%);
        }
        .spainhNavbar{
            padding-right: 0 !important;
        }
        .spainhNavbar .navbar-brand img{
            height: 60px !important;
        }
        .spainhNavbar .collapse:not(.show){
            display: none !important;
        }
    }
    @media(max-width:640px){
        .copyright-footer{
            display:block !important;
        }
        .Cookie--dark-lime{
            padding: 0 1.25em !important;
        }
        .Cookie--dark-lime p{
            margin-bottom:20px !important;
        }
    }
</style>
